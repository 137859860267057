import React from "react"
import "./whyPartner.scss"
import BlueTick from "../../images/partner/bluetick.svg"
import { data } from "./helper"

const WhyPartner = () => {
  return (
    <div className="whyPartnerWrapper">
      <div className="whyPartnerContent">
        <h1 className="whyPartnerHeading">Why Partner With SHAPE</h1>
        <div className="whyPartnerTableDesktop">
          <div>
            <div className="whyPartnerFeature whyPartnerItem whyPartnerSubHeading"></div>
            {data?.map((x) => (
              <div className="whyPartnerFeature whyPartnerItem">
                {x.feature}
              </div>
            ))}
          </div>
          <div className="whyPartnerDivider"></div>
          <div>
            <div className="whyPartnerOthers whyPartnerItem whyPartnerSubHeading">
              Other Solutions
            </div>
            {data?.map((x) => (
              <div className="whyPartnerOthers whyPartnerItem">
                {x.otherSolutions}
              </div>
            ))}
          </div>

          <div className="whyPartnerDivider"></div>
          <div>
            <div className="whyPartnerShape whyPartnerItem whyPartnerSubHeading">
              <b>SHAPE</b>
            </div>
            {data?.map((x) => (
              <div className="whyPartnerShape whyPartnerItem">
                <img src={BlueTick} /> {x.shape}
              </div>
            ))}
          </div>
        </div>
        <div className="whyPartnerTableMobile">
          {data?.map((x) => (
            <div className="whyPartnerMobItem">
              <div className="whyPartnerMobFeature">
                <span>{x.feature}</span>
                <div></div>
              </div>
              <div className="whyPartnerMobOthers">
                <p className="whyPartnerMobSubHead">
                  <b>Other Solutions</b>
                </p>
                <p>{x.otherSolutions}</p>
              </div>
              <div className="whyPartnerMobShape">
                <p className="whyPartnerMobSubHead">
                  <b>SHAPE</b>
                </p>
                <div className="whyPartnerMobDesc">
                  <img src={BlueTick} alt="shape" />
                  <span>{x.shape}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default WhyPartner
