import React, { useState, useEffect, useRef } from "react"
import "./partner-works.scss"

import HowWorks from "../../images/partner/howWorks.svg"
import Map from "../../images/partner/partnerMap.svg"
import MapMobile from "../../images/partner/partnerMapMobile.svg"
import JoystickMob from "../../images/partner/map-mob.svg"

export const PartnerWorks = () => {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const ref = useRef(null)
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting)
    })
    console.log("intersect", isIntersecting)
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [isIntersecting])
  return (
    <>
      <div className="pHowWorksWrapper">
        <div className="pHowWorksContent">
          <h1 className="pHowWorksHeading">How it Works</h1>
          <p className="pHowWorksDesc">
            We do the leg work, you get the spotlight.
          </p>
          <div className="pHowWorksVisual">
            <img src={HowWorks} alt="how it works" />
          </div>

          <p className="pHowWorksDesc noMargin">
            Deliver repeatable, scalable value to your clients.
          </p>
        </div>
      </div>
      <div className="pHowWorksWrapper whoWorks">
        <div className="pHowWorksContent">
          <h1 className="pHowWorksHeading">Who We Work With</h1>
          <p className="pHowWorksDesc">
            SHAPE is a powerful and flexible system that goes wherever you are.
          </p>
          <div className="pHowWorksFlex">
            <div>Consultants</div>
            <div>Specialists</div>
            <div>Academia</div>
          </div>
          <div className="pHowWorksVisual" ref={ref}>
            <img
              src={JoystickMob}
              alt="Drag"
              className={`dragIcon ${isIntersecting ? "hide" : ""}`}
            />
            <img
              src={Map}
              alt="Who we work with!"
              className="pHowWorksVisualDesktop"
            />
            <img
              src={MapMobile}
              alt="Who we work with!"
              className="pHowWorksVisualMob"
            />
          </div>
        </div>
      </div>
    </>
  )
}
