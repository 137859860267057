const data = [
  {
    feature: "Product",
    otherSolutions: "Focus on one or two issues",
    shape: "A flexible, modular, and personalised system.",
  },
  {
    feature: "Methodology",
    otherSolutions: "Lacking scientific rigour",
    shape: "Based on 25+ scientific models and 100+ peer reviewed studies.",
  },
  {
    feature: "Time Consumption",
    otherSolutions: "Manual systems delay results",
    shape:
      "Fully automated system allows you to deliver value up to 4x faster.",
  },
  {
    feature: "Value Add",
    otherSolutions: "Questionable ROI",
    shape: "Insights library to build custom reports and deliver clear ROI.",
  },
  {
    feature: "Marketing Support",
    otherSolutions: "Limited",
    shape: "Sales enablement assets to help you win business.",
  },
  {
    feature: "Technical Support",
    otherSolutions: "Limited",
    shape:
      "Launch training, ongoing sales and service support, dedicated wiki.",
  },
]

export { data }
