import React from "react"
import "./partner.scss"
import Link from "gatsby-link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PartnerDelivers } from "../components/partner-delivers/partner-delivers"
import { PartnerWorks } from "../components/partner-works/partner-works"
import WhyPartner from "../components/whyPartner/whyPartner"

const Partner = () => {
  return (
    <Layout>
      <SEO title="Partner" keywords={[`gatsby`, `application`, `react`]} />
      <PartnerDelivers />
      <PartnerWorks />
      <WhyPartner />
    </Layout>
  )
}

export default Partner
