import React from "react"
import "./partner-delivers.scss"
import ArrowRight from "../../images/learnmore/arrowRight-light.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft-light.svg"
import Insights from "../../images/partner/insights.svg"
import Quicker from "../../images/partner/quicker.svg"
import HeadStart from "../../images/partner/headstart.svg"
import Slider from "react-slick"
import { data, getSettings } from "./helper"
export const PartnerDelivers = () => {
  let settings = getSettings(
    <img src={ArrowRight} alt="next" style={{ right: "40px !important" }} />,
    <img src={ArrowLeft} alt="prev" style={{ left: "40px !important" }} />
  )
  let imgMap = {
    insights: Insights,
    quicker: Quicker,
    headstart: HeadStart,
  }
  return (
    <div className="pdeliverWrapper">
      <div className="pdeliverContent">
        <h1 className="pdeliverHeading">Become a SHAPE Partner</h1>
        <p className="pdeliverDesc">
          Deliver results to your clients 4x faster with SHAPE.
        </p>
        <div className="pdeliverCardsWrapper">
          <Slider {...settings}>
            {data &&
              data?.map((card, index) => {
                return (
                  <div
                    key={`${card.contentImg}-${index}`}
                    className={`pdeliverCard ${
                      card.contentImg === "quicker" ? "quicker" : ""
                    }`}
                  >
                    <p
                      dangerouslySetInnerHTML={{ __html: card?.heading }}
                      className="pdeliverCardHeading"
                    />
                    <div className="pdeliverCardImg">
                      <img src={imgMap[card.contentImg]} />
                    </div>
                  </div>
                )
              })}
          </Slider>
        </div>
      </div>
    </div>
  )
}
