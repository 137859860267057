const getSettings = (right, left) => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: right,
    prevArrow: left,
  }
  return settings
}

const data = [
  {
    heading:
      "<b>Quicker to see, faster to solve</b><br/><br/>Identify all the challenges in your client's business quickly, scientifically, and holistically.<br/><br/>Get all the intelligence in weeks instead of months for rapid and high impact interventions.",
    contentImg: "quicker",
  },
  {
    heading:
      "<b>Insights no one else has</b><br/><br/>Get insights into your client's business you wouldn't normally see with a customised report made just for you.<br/><br/>Everything's set up so you're perfectly positioned to step in and execute.",
    contentImg: "insights",
  },
  {
    heading:
      "<b>Get a headstart through inspired action</b><br/><br/>Everyone in your client's business automatically gets a personal report full of guidance.<br/><br/>Insights that compel action and propel everyone forward.",
    contentImg: "headstart",
  },
]

export { getSettings, data }
